/* .bmc-parent{
  width: 100%;
} */

.bmc-intermediate{
  border: 1px solid lightgray;
  border-radius: 50rem;
  margin: 2em;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 27%);

}

.bmc-container{
  display: flex;
  justify-content: center;
  padding: 1em;
}

.bmc-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 14em;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 50rem;
  border: 1px solid;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: white;
  text-decoration: none;
  color: #4687ff;
  border-color: #2487eb;
}

.bmc-button:hover {
  background-color:#4687ff;
  color: white;
}

.buymeCoffee-text {
  margin-left: 1em;
  margin-right: 1em;
}

.bmc-span-applause{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: 100%;
  width: 40px;
  height: 40px;

}
