.button-container {
  display: flex;
  justify-content: center;
  padding: 7px;

}

.department-button {
  width: 95%;
  background-color: gray;
  color: white;
  border: none;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 2px 4px 5px rgb(0 0 0 / 63%);
}

/* .department-button:hover{
  background-color: green;
} */

.department-button:hover {
  background-color: green;
}


/* @media (hover: none) {
  .department-button:active {
    background-color: green;
  }
} */

@media (orientation: portrait) {
  .department-button:hover {
  background-color: grey;
  }
  .department-button:active {
    background-color: green;
  }
}

@media (orientation: landscape) {

}



.image {
  width: 100%;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5); /* Set the background color to a semi-transparent black */
  backdrop-filter: blur(5px); /* Add a blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3); /* Set the border color to a semi-transparent white */
  border-top-color: #fff; /* Set the top border color to white */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite; /* Add a spinning animation */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-data-message {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.title{
  text-align: center;
}

.footer{
  text-align: center;
}


